import "../../layout.css";
import "./loginInput.css";

function LoginInput({name, type, placeholder, handleInput}) {
    return (
        <input className={"login-input"} name={name} type={type} placeholder={placeholder} onChange={handleInput} />
    );
}

export default LoginInput;
