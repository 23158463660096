import "../../layout.css";
import "./uploadLogo.css";
import React from "react";

function UploadLogo({text, onChange, disabled}) {
    return (
        <input style={{width: "100%"}} className={disabled ? "upload-logo-disabled" : "upload-logo"} type="file" accept="image/png, image/jpeg" onChange={onChange} placeholder={text} disabled={disabled} />
    );
}

export default UploadLogo;
