import "../../layout.css";
import "./navbar.css";
import logo from "../../images/logo.svg";
import {useAuth} from "../../hooks/AuthProvider";
import ActionButton from "../ActionButton/ActionButton";
import React from "react";

function Navbar({date, showDate}) {
    const auth = useAuth();
    return (
        <div className={"navbar"}>
            <div className={"navbar-container"}>
                <img src={logo} width={166} height={40} alt={"logo"}/>
                <div className={"flex align-center gap-24"}>
                    {showDate && (<span className={"navbar-version"}>Aktualna wersja menu z dnia: <span className={"navbar-version-date"}>{date}</span></span>)}
                    <div className={"navbar-logout-button-div"}>
                        <ActionButton onClick={() => {auth.logOut();}} text={"Wyloguj"} height={"48px"} whiteBorder={false} transparent={false} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
