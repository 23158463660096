import React, {useState} from "react";
import "../../layout.css";
import "./passwordRemind.css";
import Button from "../../components/Button/Button";
import logo from "../../images/logo.svg";
import bar from "../../images/bar.svg";
import LoginInput from "../../components/LoginInput/LoginInput";
import {passwordRemind} from "../../services/register";
import AlertModal from "../../components/AlertModal/AlertModal";
import {Link} from "react-router-dom";

function PasswordRemind() {
    const [input, setInput] = useState({
        email: "",
        login: "",
    });
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const [showAlertModal, setShowAlertModal] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        setLoginButtonDisabled(true);
        passwordRemind(input).then(r => {
            setLoginError(false);
            setLoginButtonDisabled(false);
            setShowAlertModal(true);
        }).catch(e => {
            setLoginError(true);
            setLoginButtonDisabled(false);
        })
    };

    return (
        <div className={"desktop-container align-center"}>
            <AlertModal visible={showAlertModal} text={"Na podany adres e-mail wysłano link do zresetowania hasła"} success={true} setModal={setShowAlertModal}/>
            <img src={logo} width={233} height={56} alt={"logo"} className={"login-logo-image"}/>
            <div className={"flex-column gap-74 col-4"}>
                <div className={"flex-column gap-11"}>
                    <h1 className={"m-0"}>Reset hasła</h1>
                    <span>Podaj adres e-mail użyty przy rejestracji oraz nazwę użytkownika dla lokalu.</span>
                </div>
                <form className={"flex-column gap-56"} onSubmit={handleSubmitEvent}>
                    <div className={"flex-column gap-16"}>
                        <div className={"flex-column gap-32"}>
                            <LoginInput name={"email"} type={"email"} placeholder={"Adres e-mail"} handleInput={handleInput} />
                            <LoginInput name={"login"} type={"text"} placeholder={"Nazwa użytkownika"} handleInput={handleInput} />
                        </div>
                        {loginError && (<span className={"login-error-message"}>Przypomnienie hasła nie powiodło się</span>)}
                        <Link to={"/login"} className={"text-right"}>Wróć do logowania</Link>
                    </div>
                    <Button type={"submit"} text={"Resetuj hasło"} disabled={loginButtonDisabled} />
                </form>
            </div>
            <div className={"col-8"}>
                <img src={bar} className={"login-bar-image"} alt={"bar"}/>
            </div>
        </div>
    );
}

export default PasswordRemind;
