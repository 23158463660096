import "../../layout.css";
import "./alertModal.css";
import error from "../../images/error.svg";
import correct from "../../images/correct.svg";
import close from "../../images/close.svg";

function AlertModal({visible, success, text, setModal}) {
    return (
        <div className={visible ? "alert-modal" : "none"}>
            <img src={close} alt={"close"} width={30} height={30} onClick={() => {setModal(false)}} className={"photos-modal-close-button"} />
            <div className={"alert-modal-content"}>
                <h3>{text}</h3>
                {success ? (
                    <img src={correct} alt={"correct"} width={72} height={72} />
                ) : (
                    <img src={error} alt={"error"} width={72} height={72} />
                )}
            </div>
        </div>
    );
}

export default AlertModal;
