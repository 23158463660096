import "../../layout.css";
import "./uploadImage.css";
import React from "react";

function UploadImage({text, onChange, disabled}) {
    return (
        <input className={disabled ? "upload-image-disabled" : "upload-image"} type="file" accept="image/png, image/jpeg" onChange={onChange} placeholder={text} disabled={disabled} />
    );
}

export default UploadImage;
