import "../../layout.css";
import "./actionButton.css";

function ActionButton({text, height, whiteBorder, transparent, onClick, disabled}) {
    if (transparent)
        return (
            <button style={{height: height}} className={"action-button-transparent"} onClick={onClick} disabled={disabled}>
                <span className={"action-button-text-transparent"}>
                    {text}
                </span>
            </button>
        );
    else
        return (
            <button style={{height: height}} className={"action-button " + (whiteBorder && "action-button-white-border ")} onClick={onClick} disabled={disabled}>
            <span className={"action-button-text"}>
                {text}
            </span>
            </button>
        );
}

export default ActionButton;
