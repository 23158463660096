import "../../layout.css";
import "./justifyText.css";
import justifyLeft from "../../images/justifyLeft.svg";
import justifyMiddle from "../../images/justifyMiddle.svg";
import justifyRight from "../../images/justifyRight.svg";

function JustifyText({header, setConfigurationField, value}) {
    return (
        <div className={"menu-input-container"}>
            <h5 className={"m-0"}>{header}</h5>
            <div className={"flex gap-6"}>
                <div className={value === "left" ? "justify-text-tile-selected" : "justify-text-tile"} onClick={() => {setConfigurationField("align", "left")}}>
                    <img src={justifyLeft} width={24} height={24} alt={"justify-left"}/>
                </div>
                <div className={value === "center" ? "justify-text-tile-selected" : "justify-text-tile"} onClick={() => {setConfigurationField("align", "center")}}>
                    <img src={justifyMiddle} width={24} height={24} alt={"justify-middle"}/>
                </div>
                <div className={value === "right" ? "justify-text-tile-selected" : "justify-text-tile"} onClick={() => {setConfigurationField("align", "right")}}>
                    <img src={justifyRight} width={24} height={24} alt={"justify-right"}/>
                </div>
            </div>
        </div>
    );
}

export default JustifyText;
