import "../../layout.css";
import "./loadingOverlay.css";

function LoadingOverlay({visible}) {
    return (
        <div className={visible ? "loading-overlay" : "none"}>
            <div className="spinner"></div>
        </div>
    );
}

export default LoadingOverlay;
