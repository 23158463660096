import "../../layout.css";
import "./menuSelect.css";
import React from "react";

function MenuSelect({header, options, name, onChange, value}) {
    return (
        <div className={"menu-input-container"}>
            <h5 className={"m-0"}>{header}</h5>
            <select name={name} className={"menu-select"} onChange={onChange}>
                {
                    Object.entries(options).map(([k,v], index) => {
                        return <option value={k} key={index} selected={value === k}>{v}</option>
                    })
                }
            </select>
        </div>
    );
}

export default MenuSelect;
