import "../../layout.css";
import "./menuColorInput.css";

function MenuColorInput({header, name, onChange, value}) {
    return (
        <div className={"menu-input-container"}>
            <h5 className={"m-0"}>{header}</h5>
            <div className={"menu-color-input-container gap-6"}>
                <input className={"menu-color-input-color"} name={name} type="color" onChange={onChange} value={value} />
                <input className={"menu-color-input-input menu-color-input-text"} name={name} onChange={onChange} value={value}/>
            </div>
        </div>
    );
}

export default MenuColorInput;
