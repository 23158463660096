import "../../layout.css";
import "./header.css";

function Header({header, text, whiteText}) {
    return (
        <div className={"header"}>
            <h3 className={"m-0 " + (whiteText && "white-text")}>{header}</h3>
            <span className={(whiteText ? "white-text" : "")}>{text}</span>
        </div>
    );
}

export default Header;
