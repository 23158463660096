import {useAuth} from "../../hooks/AuthProvider";
import {useState} from "react";
import "../../layout.css";
import "./login.css";
import Button from "../../components/Button/Button";
import logo from "../../images/logo.svg";
import bar from "../../images/bar.svg";
import LoginInput from "../../components/LoginInput/LoginInput";
import { Link } from "react-router-dom";

function Login() {
    const [input, setInput] = useState({
        login: "",
        password: "",
    });
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const auth = useAuth();

    const handleSubmitEvent = (e) => {
        setLoginButtonDisabled(true);
        e.preventDefault();
        auth.loginAction(input).then(r => {
            console.log(r);
        }).catch(e => {
            setLoginError(true);
            setLoginButtonDisabled(false);
        });
        return;
    };

    return (
        <div className={"desktop-container align-center"}>
            <img src={logo} width={233} height={56} alt={"logo"} className={"login-logo-image"}/>
            <div className={"flex-column gap-74 col-4"}>
                <div className={"flex-column gap-11"}>
                    <h1 className={"m-0"}>Logowanie</h1>
                    <span>Podaj unikalną nazwę użytkownika i hasło, aby dostać się do menu Twojego lokalu.</span>
                </div>
                <form className={"flex-column gap-56"} onSubmit={handleSubmitEvent}>
                    <div className={"flex-column gap-16"}>
                        <div className={"flex-column gap-32"}>
                            <LoginInput name={"login"} type={"text"} placeholder={"Nazwa użytkownika"} handleInput={handleInput} />
                            <LoginInput name={"password"} type={"password"} placeholder={"Hasło"} handleInput={handleInput} />
                        </div>
                        {loginError && (<span className={"login-error-message"}>Niepoprawne dane logowania</span>)}
                        <Link to={"/forgot-password"} className={"text-right"}>Zapomniałeś hasła?</Link>
                    </div>
                    <Button type={"submit"} text={"Zaloguj się"} disabled={loginButtonDisabled} />
                </form>
            </div>
            <div className={"col-8"}>
                <img src={bar} className={"login-bar-image"} alt={"bar"}/>
            </div>
        </div>
    );
}

export default Login;
