import "../../layout.css";
import "./dashboard.css";
import Navbar from "../../components/Navbar/Navbar";
import Header from "../../components/Header/Header";
import MenuInput from "../../components/MenuInput/MenuInput";
import ActionButton from "../../components/ActionButton/ActionButton";
import playButton from "../../images/play-button.svg";
import document from "../../images/document.svg";
import faq from "../../images/faq.svg";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {SimpleMdeReact} from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import {useAuth} from "../../hooks/AuthProvider";
import {getGallery, getMenu, saveConfig, saveMenu, styleMarkdown, uploadImage} from "../../services/menu";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import UploadImage from "../../components/UploadImage/UploadImage";
import PhotosModal from "../../components/PhotosModal/PhotosModal";
import DarbovenPhotosModal from "../../components/DarbovenPhotosModal/DarbovenPhotosModal";
import MenuSelect from "../../components/MenuSelect/MenuSelect";
import MenuColorInput from "../../components/MenuColorInput/MenuColorInput";
import JustifyText from "../../components/JustifyText/JustifyText";
import UploadLogo from "../../components/UploadLogo/UploadLogo";
import ReactMarkdown from "react-markdown";
import AlertModal from "../../components/AlertModal/AlertModal";
import {fontOptions} from "../../variables";
import {useNavigate} from "react-router-dom";
import MenuSwitch from "../../components/MenuSwitch/MenuSwitch";
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import darbovenLogo from "../../images/darbovenLogo.svg";
import {ErrorBoundary} from "react-error-boundary";
import QrCodeWithLogo from "qrcode-with-logos";

function MyFallbackComponent({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <p>Aby wygenerować podgląd popraw błędy w formularzu edycji menu.</p>
            <button onClick={resetErrorBoundary}>Odśwież menu</button>
        </div>
    )
}

function Dashboard() {
    const navigate = useNavigate();
    const [overlay, setOverlay] = useState(true);
    const [modal, setModal] = useState(false);
    const [darbovenModal, setDarbovenModal] = useState(false);
    const [filenames, setFilenames] = useState([]);
    const [userGalleryPhotos, setUserGalleryPhotos] = useState([]);
    const auth = useAuth();
    const [menu, setMenu] = useState({});
    const [value, setValue] = useState("");
    const [uuid, setUuid] = useState("");
    const [url, setUrl] = useState("");
    const [lastPushedAt, setLastPushedAt] = useState("");
    const [qrImage, setQRImage] = useState(null);

    const [logo, setLogo] = useState(undefined);
    const [logoFile, setLogoFile] = useState(undefined);

    const [backgroundImage, setBackgroundImage] = useState(undefined);
    const [backgroundImageFile, setBackgroundImageFile] = useState(undefined);

    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [savePublishButtonDisabled, setSavePublishButtonDisabled] = useState(false);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);

    async function prepareMenuConfiguration(data) {
        delete data["backgroundImgPath"];
        delete data["logoImgPath"];
        return data;
    }

    useEffect(() => {
        getMenu(auth.token).then(async r => {
            setMenu(r.data[0]);
            setValue(r.data[0]?.menuDraft?.content);
            setUuid(r.data[0]?.menuPublished?.uuid);
            setUrl(r.data[0]?.menuPublished?.url);
            setLastPushedAt(r.data[0]?.menuPublished?.lastPushedAt);
            if (r.data[0]?.menuConfiguration?.logoImgPath) {
                setLogo(`${process.env.REACT_APP_SERVER_URL}${r.data[0]?.menuConfiguration?.logoImgPath}`);
                new QrCodeWithLogo({
                    content: `${process.env.REACT_APP_CLIENT_URL}menu/${r.data[0]?.menuPublished?.url}`,
                    width: 380,
                    download: false,
                    logo: {
                        src: `${process.env.REACT_APP_SERVER_URL}${r.data[0]?.menuConfiguration?.logoImgPath}`,
                        logoSize: 0.15
                    }
                }).getImage().then(response => {
                    setQRImage(response.src);
                })
            }
            if (r.data[0]?.menuConfiguration?.backgroundImgPath) {
                setBackgroundImage(`${process.env.REACT_APP_SERVER_URL}${r.data[0]?.menuConfiguration?.backgroundImgPath}`);
            }
            if (r.data[0]?.menuConfiguration)
                setConfiguration(await prepareMenuConfiguration(r.data[0]?.menuConfiguration));
            else
                setConfiguration({
                    fontHeader: "exo2",
                    colorHeader: "#000000",
                    fontText: "exo2",
                    colorText: "#000000",
                    qrCodeColor: "#000000",
                    backgroundColor: "#FFFFFF",
                    align: "left",
                    shadowMode: false,
                });
            setOverlay(false);
        }).catch(e => {
            console.log(e);
        });
    }, []);

    function save() {
        if (value === "") {
            setShowAlertModal(true);
            setAlertModalMessage("Menu nie może być puste");
            setAlertModalSuccess(false);
        } else {
            setSaveButtonDisabled(true);
            saveConfig(auth.token, configuration, logoFile, backgroundImageFile, logo, backgroundImage)
                .then(r => {
                    saveMenu(auth.token, value, false)
                        .then(r => {
                            setSaveButtonDisabled(false);

                            setShowAlertModal(true);
                            setAlertModalMessage("Zapisano menu");
                            setAlertModalSuccess(true);
                        })
                        .catch(e => {
                            setSaveButtonDisabled(false);

                            setShowAlertModal(true);
                            setAlertModalMessage("Nie udało się zapisać menu");
                            setAlertModalSuccess(false);
                        })
                })
                .catch(e => {
                    setSaveButtonDisabled(false);

                    setShowAlertModal(true);
                    setAlertModalMessage("Nie udało się zapisać konfiguracji");
                    setAlertModalSuccess(false);
                })
        }
    }

    function saveAndPublish() {
        if (value === "") {
            setShowAlertModal(true);
            setAlertModalMessage("Menu nie może być puste");
            setAlertModalSuccess(false);
        } else {
            setSavePublishButtonDisabled(true);
            saveConfig(auth.token, configuration, logoFile, backgroundImageFile, logo, backgroundImage)
                .then(r => {
                    saveMenu(auth.token, value, true)
                        .then(r => {
                            setSavePublishButtonDisabled(false);

                            setShowAlertModal(true);
                            setAlertModalMessage("Zapisano menu");
                            setAlertModalSuccess(true);
                        })
                        .catch(e => {
                            setSavePublishButtonDisabled(false);

                            setShowAlertModal(true);
                            setAlertModalMessage("Nie udało się zapisać menu");
                            setAlertModalSuccess(false);
                        })
                })
                .catch(e => {
                    setSavePublishButtonDisabled(false);

                    setShowAlertModal(true);
                    setAlertModalMessage("Nie udało się zapisać konfiguracji");
                    setAlertModalSuccess(false);
                })
        }
    }

    function upload(event) {
        setUploadButtonDisabled(true);
        uploadImage(auth.token, event.target.files[0])
            .then(r => {
                setUploadButtonDisabled(false);

                setShowAlertModal(true);
                setAlertModalMessage("Dodano obrazek");
                setAlertModalSuccess(true);
            })
            .catch(e => {
                setUploadButtonDisabled(false);

                setShowAlertModal(true);
                setAlertModalMessage("Nie udało się dodać obrazka");
                setAlertModalSuccess(false);
            })
    }

    function uploadLogo(event) {
        setLogo(URL.createObjectURL(event.target.files[0]));
        setLogoFile(event.target.files[0]);
        new QrCodeWithLogo({
            content: `${process.env.REACT_APP_CLIENT_URL}menu/${url}`,
            width: 380,
            download: false,
            logo: {
                src: `${URL.createObjectURL(event.target.files[0])}`,
                logoSize: 0.15
            }
        }).getImage().then(response => {
            setQRImage(response.src);
        })
    }

    async function uploadBackgroundImage(event) {
        setBackgroundImage(URL.createObjectURL(event.target.files[0]));
        setBackgroundImageFile(event.target.files[0]);
        await style();
    }

    const onChange = useCallback((value) => {
        setValue(value);
    }, []);

    const handleLoadingGallery = (e) => {
        setModal(true);
        getGallery(auth.token)
            .then(r => {
                setUserGalleryPhotos(r?.data?.items);
            })
            .catch(e => {

            })
    }

    useEffect(() => {
        let files = require.context('../../../public/darboven', false, /\.(png|jpe?g|svg)$/);
        setFilenames(files.keys());
    }, []);

    const [configuration, setConfiguration] = useState({});

    const handleConfigurationInput = (e) => {
        const { name, value } = e.target;
        setConfiguration((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleShadowMode = (e) => {
        const value = e.target.checked;
        setConfiguration((prev) => ({
            ...prev,
            ["shadowMode"]: value,
        }));
    };

    const setConfigurationField = (name, value) => {
        setConfiguration((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState("");
    const [alertModalSuccess, setAlertModalSuccess] = useState(true);

    async function style() {
        await styleMarkdown(configuration, fontOptions, true, backgroundImage);
    }

    useEffect(() => {
        style().then(r => console.log(r))
    }, [configuration, window.document.getElementsByClassName("markdown-container"), value, backgroundImage]);

    function removeBackgroundImage() {
        setBackgroundImage(null);
        setBackgroundImageFile(null);
    }

    const markdownEditorOptions = useMemo(() => {
        return {
            hideIcons: ["preview", "side-by-side", "fullscreen"],
            spellChecker: false,
        };
    }, []);

    const [lineAndCursor, setLineAndCursor] = useState(null);

    const getLineAndCursorCallback = useCallback((position) => {
        setLineAndCursor(position);
    }, []);

    function addToValue(string) {
        let array = value.split('\n');
        let row = array[lineAndCursor.line].split('');
        if (lineAndCursor.ch > 0) {
            row[lineAndCursor.ch - 1] = row[lineAndCursor.ch - 1] + '\n' +string + '\n';
        } else {
            row[lineAndCursor.ch] = '\n' +string + '\n';
        }
        array[lineAndCursor.line] = row.join('')
        let newString = array.join('\n')
        setValue(newString);
    }

    useEffect(() => {
        lineAndCursor &&
        console.info("Hey I'm line and cursor info!", lineAndCursor);
    }, [lineAndCursor]);

    if (auth.user)
        return (
            <main>
                {auth.roles?.includes("ROLE_ADMIN") ? (
                    <>
                        Nieuprawniony użytkownik
                    </>
                ) : (
                    <>
                    <LoadingOverlay visible={overlay} />
                    <PhotosModal visible={modal} setModal={setModal} photos={userGalleryPhotos} addToValue={addToValue} />
                    <DarbovenPhotosModal visible={darbovenModal} setModal={setDarbovenModal} photos={filenames} addToValue={addToValue} />
                    <AlertModal visible={showAlertModal} text={alertModalMessage} success={alertModalSuccess} setModal={setShowAlertModal}/>
                    <Navbar date={lastPushedAt} showDate={true} />
                        <div className={"desktop-container dashboard-margin space-between"}>
                            <div className={"col-8 mr20 flex-column gap-40"}>
                                <div className={"dashboard-tile dashboard-border"}>
                                    <Header header={"Witaj w TwojeMenu.com"} text={"W tym miejscu możesz dodać zdjęcie, które będzie w tle Twojego menu oraz logo, które pojawi się na górze strony z menu. Nazwy lokalu i numeru NIP nie możesz zmienić. Są to unikalne identyfikatory Twoje lokalu. Sugerowana wielkość grafik opisana jest w poradniku w Szybkiej pomocy."} whiteText={false} />
                                    <div className={"flex"}>
                                        <div className={"col-4 mr32 flex-column gap-16"}>
                                            <MenuInput header={"Nazwa lokalu:"} disabled={true} value={menu?.localName} />
                                            <MenuInput header={"NIP:"} disabled={true} value={menu?.nip} />
                                        </div>
                                        <div className={"col-4 mr32"}>
                                            <h5 className={"m-0 mb8"}>Zdjęcie w tle:</h5>
                                            <div className={"dashboard-logo-container mb4"}>
                                                {backgroundImage && (
                                                    <img src={backgroundImage} width={166} height={40} alt={"backgroundImage"} className={"dashboard-logo"}/>
                                                )}
                                            </div>
                                            <span className={"dashboard-preview-text mb21"}>Podgląd</span>
                                            <div className={"flex-column gap-6"}>
                                                <UploadLogo text={"Prześlij"} onChange={(event) => {uploadBackgroundImage(event)}} disabled={false} />
                                                {backgroundImage && (
                                                    <ActionButton onClick={() => {removeBackgroundImage()}} text={"Usuń obrazek"} height={"40px"} whiteBorder={false} transparent={false} />
                                                )}
                                            </div>
                                        </div>
                                        <div className={"col-4"}>
                                            <h5 className={"m-0 mb8"}>Logo lokalu:</h5>
                                            <div className={"dashboard-logo-container mb4"}>
                                                {logo && (
                                                    <img src={logo} width={166} height={40} alt={"logo"} className={"dashboard-logo"}/>
                                                )}
                                            </div>
                                            <span className={"dashboard-preview-text mb21"}>Podgląd</span>
                                            <UploadLogo text={"Prześlij"} onChange={(event) => {uploadLogo(event)}} disabled={false} />
                                        </div>
                                    </div>
                                </div>
                                <div className={"dashboard-tile dashboard-border"}>
                                    <Header header={"Główne opcje"} text={"Przy budowie menu masz do dyspozycji font dla nagłówków oraz font dla tekstów wraz z wyborem kolorów. Wyrównywanie tekstu dotyczy całego menu i wszystkich elementów. Możesz jednak w tekście dodatkowo formatować kolumny i tabelki. Więcej dowiesz się z Poradnika w Szybkiej pomocy. Tryb ciemny menu ustawia całe tło w czerni. Pamiętaj, aby ręcznie zmienić kolor fontów jeśli chcesz korzystać z trybu ciemnego."} whiteText={false} />
                                    <div className={"flex"}>
                                        <div className={"col-4 mr32 flex-column gap-16"}>
                                            <MenuSelect header={"Font nagłówka:"} options={fontOptions} name={"fontHeader"} onChange={handleConfigurationInput} value={configuration?.fontHeader} />
                                            <MenuColorInput header={"Font nagłówka kolor:"} name={"colorHeader"} value={configuration?.colorHeader} onChange={handleConfigurationInput} />
                                            <MenuColorInput header={"Kolor tła:"} name={"backgroundColor"} value={configuration?.backgroundColor} onChange={handleConfigurationInput} />
                                        </div>
                                        <div className={"col-4 mr32 flex-column gap-16"}>
                                            <MenuSelect header={"Font tekstu:"} options={fontOptions} name={"fontText"} onChange={handleConfigurationInput} value={configuration?.fontText} />
                                            <MenuColorInput header={"Font tekstu kolor:"} name={"colorText"} value={configuration?.colorText} onChange={handleConfigurationInput} />
                                            <MenuSwitch header={"Tryb ciemny dla menu:"} value={configuration?.shadowMode} name={"shadowMode"} onChange={handleShadowMode}/>
                                        </div>
                                        <div className={"col-4 mr32 flex-column gap-16"}>
                                            <JustifyText header={"Justowanie tekstu:"} setConfigurationField={setConfigurationField} value={configuration?.align}/>
                                            <MenuColorInput header={"Kolor QR"} name={"qrCodeColor"} value={configuration?.qrCodeColor} onChange={handleConfigurationInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className={"flex"}>
                                    <div className={"col-6 mr20"}>
                                        <div className={"dashboard-tile dashboard-border"}>
                                            <Header header={"Zbuduj swoje menu"} text={"W tym miejscu budujesz całe menu.\n" +
                                                "Edytor menu działa w języku znaczników Markdown, które przyspieszają edycję. Podstawowe formatowanie i wstawianie grafik znajdują się na pasku poniżej. Dodatkowa pomoc znajduje się z prawej strony pod linkiem lub na filmiku."} whiteText={false} />
                                            <SimpleMdeReact  value={value} onChange={onChange} options={markdownEditorOptions} getLineAndCursor={getLineAndCursorCallback} />
                                        </div>
                                    </div>
                                    <div className={"col-6 ml20"}>
                                        <div className={"dashboard-tile dashboard-border"}>
                                            <Header header={"Podgląd menu"} text={"Tak będzie wyglądało Twoje menu na telefonie klienta."} whiteText={false} />
                                            <div className={"dashboard-preview-container"}>
                                                <div className={"dashboard-phone-preview-container"}>
                                                    <div className={"dashboard-phone-preview"}>
                                                        <div className={"dashboard-phone-preview-content"}>
                                                            <ErrorBoundary FallbackComponent={MyFallbackComponent}>
                                                                {logo && (
                                                                    <img src={`${logo}`} alt={"menu-logo"} className={"menu-logo"}/>
                                                                )}
                                                                <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} className={"markdown-container"}  />
                                                                <div className={"menu-darboven-logo-container"}>
                                                                    Sponsored by
                                                                    <img src={darbovenLogo} alt={"darboven-logo"} className={"menu-darboven-logo"} onClick={() => {window.open('https://darbovensklep.pl/')}}/>
                                                                </div>
                                                            </ErrorBoundary>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-4 ml20 flex-column gap-40"}>
                                <div className={"dashboard-quick-help-tile dashboard-border"}>
                                    <Header header={"Szybka pomoc"} text={"Zanim do nas napiszesz prosimy zapoznaj się Poradnikiem, sprawdź pytania i odpowiedzi i obejrzyj film instruktażowy."} whiteText={false} />
                                    <div>
                                        <div className={"flex gap-16 dashboard-quick-help-container dashboard-quick-help-border"}>
                                            <img src={playButton} width={24} height={24} alt={"playButton"}/>
                                            <a className={"dashboard-quick-help-text cursor-not-allowed"} title={"Film będzie dostępny wkrótce"}>Obejrzyj film instruktażowy</a>
                                        </div>
                                        <div className={"flex gap-16 dashboard-quick-help-container dashboard-quick-help-border"}>
                                            <img src={document} width={24} height={24} alt={"playButton"}/>
                                            <a href={"/manual.pdf"} target={"_blank"} className={"dashboard-quick-help-text cursor-pointer"}>Poradnik korzystania z kreatora</a>
                                        </div>
                                        <div className={"flex gap-16 dashboard-quick-help-container"}>
                                            <img src={faq} width={24} height={24} alt={"playButton"}/>
                                            <span className={"dashboard-quick-help-text"}>Najczęściej zadawane pytania</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"dashboard-tile dashboard-border align-center"}>
                                    {uuid && (
                                        <>
                                            <img className={"image"} src={qrImage} />
                                            <ActionButton onClick={() => {window.open(`/pdf`);}} text={"Wygeneruj PDF"} height={"48px"} whiteBorder={false} transparent={false} />
                                        </>
                                    )}
                                </div>
                                <div className={"dashboard-tile dashboard-border"}>
                                    <Header header={"Biblioteka zdjęć"} text={"Gotowe zdjęcia kaw i napojów od Darboven oraz możliwość tworzenia Twojej własnej biblioteki zdjęć produktów do częstego korzystania."} whiteText={false} />
                                    <ActionButton onClick={() => {setDarbovenModal(true);}} text={"Biblioteka Darboven"} height={"48px"} whiteBorder={false} transparent={true} />
                                    <ActionButton onClick={handleLoadingGallery} text={"Biblioteka własna"} height={"48px"} whiteBorder={false} transparent={true} />
                                    <UploadImage text={"Prześlij własne zdjęcie"} onChange={(event) => {upload(event)}} disabled={uploadButtonDisabled} />
                                </div>
                                <div className={"dashboard-tile dashboard-tile-purple dashboard-border"}>
                                    <Header header={"Zapisz swoją pracę"} text={"Pamiętaj zapisz swoją pracę. Masz dwa tryby. Zapis tymczasowy oraz zapis i publikację dla klientów."} whiteText={true} />
                                    <ActionButton onClick={() => {save()}} disabled={saveButtonDisabled} text={"Zapisz menu"} height={"48px"} whiteBorder={true} transparent={false} />
                                    <ActionButton onClick={() => {saveAndPublish()}} disabled={savePublishButtonDisabled} text={"Zapisz i opublikuj"} height={"48px"} whiteBorder={true} transparent={false} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </main>
        );
    else
        return (
            <>
                Niezalogowany
            </>
        );
}

export default Dashboard;