import React, {useState} from "react";
import "../../layout.css";
import "./resetPassword.css";
import Button from "../../components/Button/Button";
import logo from "../../images/logo.svg";
import bar from "../../images/bar.svg";
import LoginInput from "../../components/LoginInput/LoginInput";
import {resetPassword} from "../../services/register";
import AlertModal from "../../components/AlertModal/AlertModal";
import {Link, useParams} from "react-router-dom";

function ResetPassword() {
    let { token } = useParams();
    const [input, setInput] = useState({
        password: "",
    });
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [notSamePasswordError, setNotSamePasswordError] = useState(false);

    const [showAlertModal, setShowAlertModal] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        if (document.getElementsByName('password')[0].value !== document.getElementsByName('confirmPassword')[0].value) {
            setNotSamePasswordError(true);
        } else {
            setNotSamePasswordError(false);
            setLoginButtonDisabled(true);
            resetPassword(input, token).then(r => {
                setLoginError(false);
                setLoginButtonDisabled(false);
                setShowAlertModal(true);
            }).catch(e => {
                setLoginError(true);
                setLoginButtonDisabled(false);
            })
        }
    };

    return (
        <div className={"desktop-container align-center"}>
            <AlertModal visible={showAlertModal} text={"Twoje hasło zostało zmienione"} success={true} setModal={setShowAlertModal}/>
            <img src={logo} width={233} height={56} alt={"logo"} className={"login-logo-image"}/>
            <div className={"flex-column gap-74 col-4"}>
                <div className={"flex-column gap-11"}>
                    <h1 className={"m-0"}>Reset hasła</h1>
                    <span>Prosimy o podanie nowego hasła w celu jego zresetowania.</span>
                </div>
                <form className={"flex-column gap-56"} onSubmit={handleSubmitEvent}>
                    <div className={"flex-column gap-16"}>
                        <div className={"flex-column gap-32"}>
                            <LoginInput name={"password"} type={"password"} placeholder={"Hasło"} handleInput={handleInput} />
                            <LoginInput name={"confirmPassword"} type={"password"} placeholder={"Potwierdź hasło"} />
                        </div>
                        {notSamePasswordError && (<span className={"login-error-message"}>Hasła nie są identyczne</span>)}
                        {loginError && (<span className={"login-error-message"}>Zresetowanie hasła nie powiodło się</span>)}
                        <Link to={"/login"} className={"text-right"}>Wróć do logowania</Link>
                    </div>
                    <Button type={"submit"} text={"Resetuj hasło"} disabled={loginButtonDisabled} />
                </form>
            </div>
            <div className={"col-8"}>
                <img src={bar} className={"login-bar-image"} alt={"bar"}/>
            </div>
        </div>
    );
}

export default ResetPassword;
