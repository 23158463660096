import "./menu.css";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getMenuById, styleMarkdown} from "../../services/menu";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import ReactMarkdown from 'react-markdown';
import {fontOptions} from "../../variables";
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm';
import darbovenLogo from '../../images/darbovenLogo.svg'

function Menu() {
    const navigate = useNavigate();
    const [overlay, setOverlay] = useState(true);
    let { id } = useParams();

    const [markdown, setMarkdown] = useState("");

    const [failedMenuLoad, setFailedMenuLoad] = useState(false);
    const [configuration, setConfiguration] = useState({});

    useEffect(() => {
        getMenuById(id).then(r => {
            setMarkdown(r.data?.content);
            setConfiguration(r.data);
            setOverlay(false);
        }).catch(e => {
            setFailedMenuLoad(true)
            setOverlay(false);
        });
    }, []);

    async function style() {
        await styleMarkdown(configuration, fontOptions, false, configuration?.backgroundImgPath);
    }

    useEffect(() => {
        style();
    }, [configuration, window.document.getElementsByClassName("markdown-container"), markdown]);

    return (
        <div>
            {!failedMenuLoad ? (
                <div>
                    <LoadingOverlay visible={overlay} />
                    <div className={"menu-container"}>
                        <div className={"menu-preview"}>
                            <div className={"menu-content"}>
                                {configuration?.logoImgPath && (
                                    <img src={`${process.env.REACT_APP_SERVER_URL}${configuration?.logoImgPath}`} alt={"menu-logo"} className={"menu-logo"}/>
                                )}
                                <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} className={"markdown-container"} />
                                <div className={"menu-darboven-logo-container"}>
                                    Sponsored by
                                    <img src={darbovenLogo} alt={"darboven-logo"} className={"menu-darboven-logo"} onClick={() => {window.open('https://darbovensklep.pl/')}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className={"menu-not-found"}>
                        <h4>Brak menu</h4>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Menu;
