import "./invite.css";
import React, {useState} from "react";
import Header from "../../components/Header/Header";
import LoginInput from "../../components/LoginInput/LoginInput";
import Button from "../../components/Button/Button";
import {useAuth} from "../../hooks/AuthProvider";
import {sendInvite} from "../../services/register";
import AlertModal from "../../components/AlertModal/AlertModal";
import Navbar from "../../components/Navbar/Navbar";

function Invite() {
    const [sendInviteDisabled, setSendInviteDisabled] = useState(false);

    const [sendInviteInput, setSendInviteInput] = useState({
        email: "",
    });

    const handleSendInviteEvent = (e) => {
        setSendInviteDisabled(true);
        e.preventDefault();
        sendInvite(auth.token, sendInviteInput)
            .then(r => {
                setSendInviteDisabled(false);

                setShowAlertModal(true);
                setAlertModalMessage("Wysłano zaproszenie");
                setAlertModalSuccess(true);
            })
            .catch(e => {
                setSendInviteDisabled(false);

                setShowAlertModal(true);
                setAlertModalMessage("Nie udało się wysłać zaproszenia");
                setAlertModalSuccess(false);
            })
    };

    const handleSendInviteInput = (e) => {
        const { name, value } = e.target;
        setSendInviteInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalMessage, setAlertModalMessage] = useState("");
    const [alertModalSuccess, setAlertModalSuccess] = useState(true);

    const auth = useAuth();
    return (
        <main>
            <Navbar date={"01.01.2024"} showDate={false} />
            <div className={"invite-container"}>
                <AlertModal visible={showAlertModal} text={alertModalMessage} success={alertModalSuccess} setModal={setShowAlertModal}/>
                {auth.roles?.includes("ROLE_ADMIN") ? (
                    <>
                        <div className={"dashboard-tile dashboard-border col-4"}>
                            <Header header={"Panel administracyjny TwojeMenu.com"} text={"W tym miejscu możesz wygenerować zaproszenie dla nowego Klienta. Pamiętaj, że wysłany link będzie ważny tylko przez 1 godzinę. Po tym czasie będzie potrzebne wysłanie kolejnego zaproszenia."} whiteText={false} />
                        </div>
                        <div className={"dashboard-tile dashboard-border col-4"}>
                            <Header header={"Wygeneruj zaproszenie"} text={"Na poniższy adres zostanie wysłane zaproszenie do utworzenia konta. Jeśli klient ma kilka lokali i korzysta z tego samego adresu e-mail wyślij my tyle zaproszeń ile ma lokali. Przy zakładaniu konta Klient będzie musiał utworzyć unikalnego użytkownika i hasło dla każdego ze swoich lokali."} whiteText={false} />
                            <form onSubmit={handleSendInviteEvent}>
                                <LoginInput name={"email"} type={"email"} placeholder={"E-mail"} handleInput={handleSendInviteInput} />
                                <Button type={"submit"} text={"Wyślij"} disabled={sendInviteDisabled} />
                            </form>
                        </div>
                    </>
                ) : (
                    <>
                        Nieuprawniony użytkownik
                    </>
                )}
            </div>
        </main>
    );
}

export default Invite;
