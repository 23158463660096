import "../../layout.css";
import "./menuSwitch.css";
import React from "react";

function MenuSwitch({header, name, value, onChange}) {
    return (
        <div className={"menu-input-container"}>
            <h5 className={"m-0"}>{header}</h5>
            <label>
                <input className={"menu-switch-input"} type={"checkbox"} name={name} onChange={onChange} checked={value} />
            </label>
        </div>
    );
}

export default MenuSwitch;