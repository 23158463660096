import "../../layout.css";
import "./darbovenPhotosModal.css";
import close from "../../images/close.svg";

function DarbovenPhotosModal({visible, setModal, photos, addToValue}) {

    function addImagePath(photo) {
        addToValue(`![alt](${process.env.REACT_APP_CLIENT_URL}darboven/${photo?.slice(2)})`);
        setModal(false);
    }

    return (
        <div className={visible ? "photos-modal" : "none"}>
            <img src={close} alt={"close"} width={30} height={30} onClick={() => {setModal(false)}} className={"photos-modal-close-button"} />
            <h5 className={"darboven-photos-modal-header"}>Kliknij w zdjęcie, aby dodać na stronę menu</h5>
            <div className={"photos-modal-content"}>
                {photos.map((photo, index) => {
                    return (<div key={index} className={"photos-modal-image-container"} onClick={() => {addImagePath(photo)}}>
                        <img src={`${process.env.REACT_APP_CLIENT_URL}darboven/${photo?.slice(2)}`} alt={"gallery-photo"} className={"photos-modal-image"} />
                    </div>);
                })}
            </div>
        </div>
    );
}

export default DarbovenPhotosModal;
