import "../../layout.css";
import "./menuInput.css";

function MenuInput({header, disabled, value}) {
    return (
        <div className={"menu-input-container"}>
            <h5 className={"m-0"}>{header}</h5>
            <input className={"menu-input"} disabled={disabled} value={value} />
        </div>
    );
}

export default MenuInput;
