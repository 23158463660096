import React, {useEffect, useState} from "react";
import {Page, View, Document, Image, StyleSheet, Text} from '@react-pdf/renderer';
import {PDFViewer} from '@react-pdf/renderer';
import QrCodeWithLogo from "qrcode-with-logos";
import {getMenu} from "../../services/menu";
import {useAuth} from "../../hooks/AuthProvider";

const styles = StyleSheet.create({
    viewer: {
        width: "99vw",
        height: "99vh"
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF'
    },
    section: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "25",
        flexWrap: "wrap",
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    tile: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    image: {
        width: "200px",
        height: "200px"
    },
    text: {
        width: "200px",
        textAlign: "center",
    }
});

function Pdf() {
    const auth = useAuth();
    const [loaded, setLoaded] = useState(false);
    const [img, setImg] = useState(null);

    const [url, setUrl] = useState("");

    useEffect(() => {
        if (auth.user) {
            getMenu(auth.token).then(async r => {
                setUrl(r.data[0]?.menuPublished?.url);
                if (r.data[0]?.menuConfiguration?.logoImgPath) {
                    new QrCodeWithLogo({
                        canvas: document.getElementById("canvas"),
                        content: `${process.env.REACT_APP_CLIENT_URL}menu/${r.data[0]?.menuPublished?.url}`,
                        width: 380,
                        download: false,
                        image: document.getElementById("image"),
                        logo: {
                            src: `${process.env.REACT_APP_SERVER_URL}${r.data[0]?.menuConfiguration?.logoImgPath}`,
                            logoSize: 0.15
                        }
                    }).getImage().then(r => {
                        setLoaded(true)
                        setImg(r.src);
                    })
                } else {
                    new QrCodeWithLogo({
                        canvas: document.getElementById("canvas"),
                        content: `${process.env.REACT_APP_CLIENT_URL}menu/${r.data[0]?.menuPublished?.url}`,
                        width: 380,
                        download: false,
                        image: document.getElementById("image"),
                    }).getImage().then(r => {
                        setLoaded(true)
                        setImg(r.src);
                    })
                }
            }).catch(e => {
                console.log(e);
            });
        }
    }, []);

    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                {(loaded && auth.user) && (
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            <View style={styles.tile}>
                                <Image src={img} style={styles.image}/>
                                <Text style={styles.text}>{url}</Text>
                            </View>
                            <View style={styles.tile}>
                                <Image src={img} style={styles.image}/>
                                <Text style={styles.text}>{url}</Text>
                            </View>
                            <View style={styles.tile}>
                                <Image src={img} style={styles.image}/>
                                <Text style={styles.text}>{url}</Text>
                            </View>
                            <View style={styles.tile}>
                                <Image src={img} style={styles.image}/>
                                <Text style={styles.text}>{url}</Text>
                            </View>
                            <View style={styles.tile}>
                                <Image src={img} style={styles.image}/>
                                <Text style={styles.text}>{url}</Text>
                            </View>
                            <View style={styles.tile}>
                                <Image src={img} style={styles.image}/>
                                <Text style={styles.text}>{url}</Text>
                            </View>
                        </View>
                    </Page>
                )}
            </Document>
        </PDFViewer>
    );
}

export default Pdf;
