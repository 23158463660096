import {useState} from "react";
import "../../layout.css";
import "./register.css";
import Button from "../../components/Button/Button";
import logo from "../../images/logo.svg";
import bar from "../../images/bar.svg";
import LoginInput from "../../components/LoginInput/LoginInput";
import {Link, useNavigate, useParams} from "react-router-dom";
import {register} from "../../services/register";

function Register() {
    let { token, email } = useParams();
    const navigate = useNavigate();
    const [input, setInput] = useState({
        email: email,
        login: "",
        localName: "",
        password: "",
        nip: ""
    });
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [notSamePasswordError, setNotSamePasswordError] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmitEvent = (e) => {
        e.preventDefault();
        if (document.getElementsByName('password')[0].value !== document.getElementsByName('confirmPassword')[0].value) {
            setNotSamePasswordError(true);
        } else {
            setNotSamePasswordError(false);
            setLoginButtonDisabled(true);
            register(token, input).then(r => {
                console.log(r);
                navigate("/login");
                setLoginButtonDisabled(false);
            }).catch(e => {
                console.log(e);
                setLoginError(true);
                setLoginButtonDisabled(false);
            })
        }
    };

    return (
        <div className={"desktop-container align-center"}>
            <img src={logo} width={233} height={56} alt={"logo"} className={"login-logo-image"}/>
            <div className={"flex-column gap-74 col-4"}>
                <div className={"flex-column gap-11"}>
                    <h1 className={"m-0"}>Rejestracja lokalu</h1>
                    <span>Jeden użytkownik może zarządzać tylko jednym lokalem. Nazwa użytkownika powinna być unikalna i nie będzie mogła być zmieniona.</span>
                </div>
                <form className={"flex-column gap-56"} onSubmit={handleSubmitEvent}>
                    <div className={"flex-column gap-16"}>
                        <div className={"flex-column gap-32"}>
                            <LoginInput name={"login"} type={"text"} placeholder={"Nazwa użytkownika"} handleInput={handleInput} />
                            <LoginInput name={"localName"} type={"text"} placeholder={"Nazwa lokalu"} handleInput={handleInput} />
                            <LoginInput name={"password"} type={"password"} placeholder={"Hasło"} handleInput={handleInput} />
                            <LoginInput name={"confirmPassword"} type={"password"} placeholder={"Potwierdź hasło"} />
                            <LoginInput name={"nip"} type={"text"} placeholder={"NIP"} handleInput={handleInput} />
                        </div>
                        {notSamePasswordError && (<span className={"login-error-message"}>Hasła nie są identyczne</span>)}
                        {loginError && (<span className={"login-error-message"}>Rejestracja nie powiodła się</span>)}
                        <Link to={"/forgot-password"} className={"text-right"}>Zapomniałeś hasła?</Link>
                    </div>
                    <Button type={"submit"} text={"Zarejestruj się"} disabled={loginButtonDisabled} />
                </form>
            </div>
            <div className={"col-8"}>
                <img src={bar} className={"login-bar-image"} alt={"bar"}/>
            </div>
        </div>
    );
}

export default Register;
