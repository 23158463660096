export const sendInvite = async (token, data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/admin/invite`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Inviting error");
    } catch (e) {
        throw new Error("Inviting error");
    }
};

export const register = async (token, data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/register?token=${token}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Registration error");
    } catch (e) {
        throw new Error("Registration error");
    }
};

export const passwordRemind = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/password/remind`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Password remind error");
    } catch (e) {
        throw new Error("Password remind error");
    }
};

export const resetPassword = async (data, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/password/${token}/reset`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Password remind error");
    } catch (e) {
        throw new Error("Password remind error");
    }
};


export default {sendInvite, register, passwordRemind, resetPassword};