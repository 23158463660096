import "../../layout.css";
import "./button.css";

function Button({type, text, disabled}) {
    return (
        <button className={"button"} type={type} disabled={disabled}>
            <span className={"button-text"}>
                {text}
            </span>
        </button>
    );
}

export default Button;
